<template>
  <el-drawer title="用户详情" :visible.sync="isDrawer" :with-header="false" size="80%" :before-close="handleClose"
    :modal-append-to-body="false">
    <div class="flex flex-dc h-full">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">用户详情</div>
        <i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
      </div>
      <div class="drawer_form flex-1">
        <t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="1" @handleEvent="handleEvent">
        </t-simple-form>
      </div>
      <div class="flex-n pall-30 flex flex-ac flex-je">
        <el-button type="primary" plain class="mr-20" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitForm">确认</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'userDetails',
  components: {
    "TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
  },
  data () {
    const checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formOpts.formData.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      identity: 'add',//身份
      detailsId: '',//详情id
      isDrawer: false,
      formOpts: {
        labelPosition: 'top',
        ref: null,
        formData: {
          userName: '', // *用户账号
          password: '', // *用户密码
          newPassword: '',// *用户确认密码
          phone: '', // *手机号
          email: '', // *邮箱
        },
        fieldList: [
          { label: '用户名', value: 'userName', type: 'input', comp: 'el-input', event: 'account' },
          { label: '手机号', value: 'phone', type: 'input', comp: 'el-input' },
          { label: '密码', value: 'password', type: 'password', comp: 'el-input', bind: { autocomplete: "new-password" } },
          { label: '确认密码', value: 'newPassword', type: 'password', comp: 'el-input', bind: { autocomplete: "new-password" } },
          { label: '邮箱', value: 'email', type: 'input', comp: 'el-input' },
        ],
        rules: {
          userName: [
            { required: true, message: '请输入账号', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ],
          newPassword: [
            { required: true, validator: validatePass, trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入昵称', trigger: 'blur' }
          ],
          phone: [
            { required: true, type: 'number', validator: checkPhone, message: '请填写正确的手机号', trigger: 'blur' }
          ],
          email: [
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
          ]
        },
        // 相关列表
        listTypeInfo: {}
      },
    }
  },
  mounted () { },
  methods: {
    // 初始化
    init (identity, data) {
      this.isDrawer = true
      this.identity = identity
      if (data) {
        this.detailsId = data.id
        this.editDetails(data)
      }
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      this.resetForm()
    },
    //获取详情数据
    editDetails (data) {
      this.$http.get('userInfo/queryById', {
        params: {
          id: data.id
        }
      }).then(({ data: res }) => {
        // this.formOpts.formData = res
        // this.formOpts.formData.newPassword = res.password
        let formData = {
          id: res.id,
          userName: res.userName, // *用户账号
          password: res.password, // *用户密码
          newPassword: res.password,// *用户确认密码
          phone: res.phone, // *手机号
          email: res.email, // *邮箱
        }
        this.formOpts.formData = formData
      })
    },
    // 触发事件
    handleEvent (type, val) {
      switch (type) {
        case 'checkbox':
          console.log('checkbox', val, type)
          break
      }
    },
    // 提交form表单
    submitForm () {
      this.formOpts.ref.validate((valid) => {
        if (!valid) return
        console.log('最终数据', this.formOpts.formData)
        if (this.detailsId) {
          this.$http.post('/userInfo/editUserInfo', this.formOpts.formData).then(res => {
            this.handleClose()
            this.$message({
              message: '编辑成功',
              type: 'success'
            })
            this.$emit('callBack')
          })
        } else {
          this.$http.post('/userInfo/addUserInfo', this.formOpts.formData).then(res => {
            this.handleClose()
            this.$message({
              message: '新增成功',
              type: 'success'
            })
            this.$emit('callBack')
          })
        }
      })
    },
    // 重置form表单
    resetForm () {
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data().formOpts.formData
      )
      this.$nextTick(() => {
        this.formOpts.ref.clearValidate()
      })
    },
    // 清除校验
    clearValidate () {
      this.formOpts.ref.clearValidate()
    }
  }
}
</script>

<style lang="less" scoped>
</style>