import { render, staticRenderFns } from "./adminDetails.vue?vue&type=template&id=7b91752c&scoped=true&"
import script from "./adminDetails.vue?vue&type=script&lang=js&"
export * from "./adminDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b91752c",
  null
  
)

export default component.exports